const mappings = [
  {
    mode: "title",
    property: "seo_title",
  },
  {
    mode: "description",
    property: "seo_description",
  },
  {
    selector: ".main-content__left-text",
    mode: "rich",
    property: "left_text",
  },
  {
    selector: ".main-content__left-email",
    mode: "nested-mailto",
    property: "contact_e-mail",
  },
  {
    selector: ".main-content__right_top",
    mode: "rich",
    property: "right_top_text",
  },
  {
    selector: ".main-content__right_list",
    mode: "rich",
    property: "right_middle_text",
  },
  {
    selector: ".main-content__right_bottom",
    mode: "plaintext",
    property: "bottom_small_text",
  },
  {
    selector: ".socials-fb",
    mode: "self-href",
    property: "facebook_link",
  },
  {
    selector: ".socials-insta",
    mode: "self-href",
    property: "instagram_link",
  },
  {
    selector: ".socials-tw",
    mode: "self-href",
    property: "twitter_link",
  },
  {
    selector: ".socials-yt",
    mode: "self-href",
    property: "youtube_link",
  },
  {
    selector: ".footer-content-below",
    mode: "rich",
    property: "footer_content_below",
  },
  {
    selector: ".footer-content-bottom-text",
    mode: "rich",
    property: "footer_note",
  },
];
export default mappings;
